import api from "@/api/index"

export const puzzleModule = {
  state: () => ({
    easyGameFinished: false,
    mediumGameFinished: false,
    hardGameFinished: false,
    finishGameData: {},
    isWinner: false,
    isGiftDataSend: false,
    restartGame: false,
    almostCompleted: false,
  }),
  mutations: {
    setFinishEasyGame(state, data) {
      state.easyGameFinished = data
    },

    setAlmostCompletedGame(state, data) {
      state.almostCompleted = data
    },

    setFinishMediumGame(state, data) {
      state.mediumGameFinished = data
    },

    setFinishHardGame(state, data) {
      state.hardGameFinished = data
    },

    restartPuzzleGame(state, data) {
      state.restartGame = data
    },

    setFinishGameData(state, payload) {
      state.finishGameData = payload;
    },

    setIsWinner(state, payload) {
      state.isWinner = payload;
    },

    setIsGiftDataSend(state, payload) {
      state.isGiftDataSend = payload;
    },
  },
  getters: {
  },
  actions: {
    finishGame({ commit }) {
      return api.game
        .getPlayerInfo({
          type: "puzzle",
        })
        .then((res) => {
          commit("setFinishGameData", res.data.data);
          commit("setIsWinner", res.data.data.won);
        })
        .catch((e) => console.error(e.response));
    },

    getGift({ commit }, payload) {
      commit("setIsGiftDataSend", payload);
    },
  }
}
