<template>
  <div>
    <b-modal
      id="puzzle-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <span class="close-icon" @click="$bvModal.hide('puzzle-modal')">
        <img
          src="@/assets/svg/icons/close.svg"
          aria-label="close"
          alt="Закрыть"
        />
      </span>

      <div class="d-flex flex-column align-items-center">
        <h1 class="title">декоративный пазл</h1>

        <p class="modal-text">
          Собирайте пазлы из плитки и проектируйте собственные <br />
          решения с Леруа Мерлен
        </p>

        <div>
          <button class="button button-green" @click="handleButtonClick">
            начать
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    handleButtonClick() {
      try {
        ym(90102394, "reachGoal", "start_game");
        _txq.push(["track", "start"]);
        _tmr.push({ type: "reachGoal", id: 3385609, goal: "Start2" });
      } catch (e) {
        console.log("analytics error => ", e);
      }
      this.$bvModal.hide("puzzle-modal");
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 42px;
  text-align: center;

  @media (max-width: 576px) {
    font-size: 28px;
    margin-top: 40px;
  }
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
  transition: 0.5s all;

  &:hover {
    opacity: 0.8;
  }
}

.button {
  width: 100%;
  max-width: 120px;
  margin-top: 20px;
}

.modal-text {
  @media (max-width: 768px) {
    br {
      display: none;
    }
  }
}
</style>
