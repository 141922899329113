export const POPITS_COUNT = 30;
export const IS_GAME_ALMOST_FINISH_COUNT = 1;
export const BACKGROUNDS = [
  {
    id: 1,
    base: require("@/assets/img/popit/bg-1.png"),
    height: require("@/assets/img/popit/height/bg-1.png"),
    finish: require("@/assets/img/popit/full-bg-1.png"),
  },
  {
    id: 2,
    base: require("@/assets/img/popit/bg-2.png"),
    height: require("@/assets/img/popit/height/bg-2.png"),
    finish: require("@/assets/img/popit/full-bg-2.png"),
  },
  {
    id: 3,
    base: require("@/assets/img/popit/bg-3.png"),
    height: require("@/assets/img/popit/height/bg-3.png"),
    finish: require("@/assets/img/popit/full-bg-3.png"),
  },
  {
    id: 4,
    base: require("@/assets/img/popit/bg-4.png"),
    height: require("@/assets/img/popit/height/bg-4.png"),
    finish: require("@/assets/img/popit/full-bg-4.png"),
  },
  {
    id: 5,
    base: require("@/assets/img/popit/bg-5.png"),
    height: require("@/assets/img/popit/height/bg-5.png"),
    finish: require("@/assets/img/popit/full-bg-5.png"),
  },
  {
    id: 6,
    base: require("@/assets/img/popit/bg-6.png"),
    height: require("@/assets/img/popit/height/bg-6.png"),
    finish: require("@/assets/img/popit/full-bg-6.png"),
  },
  {
    id: 7,
    base: require("@/assets/img/popit/bg-7.png"),
    height: require("@/assets/img/popit/height/bg-7.png"),
    finish: require("@/assets/img/popit/full-bg-7.png"),
  },
  {
    id: 8,
    base: require("@/assets/img/popit/bg-8.png"),
    height: require("@/assets/img/popit/height/bg-8.png"),
    finish: require("@/assets/img/popit/full-bg-8.png"),
  },
  // {
  //   id: 9,
  //   base: require("@/assets/img/popit/bg-9.png"),
  //   height: require("@/assets/img/popit/height/bg-9.png"),
  //   finish: require("@/assets/img/popit/full-bg-9.png"),
  // },
  {
    id: 10,
    base: require("@/assets/img/popit/bg-10.png"),
    height: require("@/assets/img/popit/height/bg-10.png"),
    finish: require("@/assets/img/popit/full-bg-10.png"),
  },
  {
    id: 11,
    base: require("@/assets/img/popit/bg-11.png"),
    height: require("@/assets/img/popit/height/bg-11.png"),
    finish: require("@/assets/img/popit/full-bg-11.png"),
  },
  {
    id: 12,
    base: require("@/assets/img/popit/bg-12.png"),
    height: require("@/assets/img/popit/height/bg-12.png"),
    finish: require("@/assets/img/popit/full-bg-12.png"),
  },
  {
    id: 13,
    base: require("@/assets/img/popit/bg-13.png"),
    height: require("@/assets/img/popit/height/bg-13.png"),
    finish: require("@/assets/img/popit/full-bg-13.png"),
  },
  {
    id: 14,
    base: require("@/assets/img/popit/bg-14.png"),
    height: require("@/assets/img/popit/height/bg-14.png"),
    finish: require("@/assets/img/popit/full-bg-14.png"),
  },
  {
    id: 15,
    base: require("@/assets/img/popit/bg-15.png"),
    height: require("@/assets/img/popit/height/bg-15.png"),
    finish: require("@/assets/img/popit/full-bg-15.png"),
  },
];
