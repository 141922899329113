import {
  POPITS_COUNT,
  BACKGROUNDS,
  IS_GAME_ALMOST_FINISH_COUNT,
} from "@/consts/popit";
import api from "@/api/index";

export const popitModule = {
  state: () => ({
    currentBackground: 1,
    items: [],
    isGameFinished: false,
    finishGameData: {},
    isWinner: false,
    isGiftDataSend: false,
    isRestartGame: false,
  }),
  mutations: {
    setItems(state, payload) {
      state.items = payload;
    },
    setIsGameFinished(state, payload) {
      state.isGameFinished = payload;
    },
    setCurrentBackground(state, payload) {
      state.currentBackground = payload;
    },
    setFinishGameData(state, payload) {
      state.finishGameData = payload;
    },
    setIsWinner(state, payload) {
      state.isWinner = payload;
    },
    setIsGiftDataSend(state, payload) {
      state.isGiftDataSend = payload;
    },
    setIsRestartGame(state, payload) {
      state.isRestartGame = payload;
    },
  },
  getters: {
    isGameAlmostFinished(state) {
      const activeElements = state.items.filter((item) => item.isActive);
      return activeElements.length <= IS_GAME_ALMOST_FINISH_COUNT;
    },
  },
  actions: {
    initGame({ commit }) {
      const items = [];

      for (let i = 0; i < POPITS_COUNT; i++) {
        items.push({
          id: i,
          isActive: true,
        });
      }

      commit("setItems", items);
    },
    restartGame({ commit, state }) {
      commit("setIsRestartGame", false);
      commit(
        "setItems",
        state.items.map((item) => {
          return {
            ...item,
            isActive: true,
          };
        })
      );
      commit("setIsGameFinished", false);
    },
    changeGame({ commit }, payload) {
      commit("setIsGameFinished", payload);
    },
    changeBackground({ commit, state }) {
      state.currentBackground < BACKGROUNDS.length
        ? commit("setCurrentBackground", state.currentBackground + 1)
        : commit("setCurrentBackground", 1);
    },
    finishGame({ commit }) {
      return api.game
        .getPlayerInfo({
          type: "popit",
        })
        .then((res) => {
          commit("setFinishGameData", res.data.data);
          commit("setIsWinner", res.data.data.won);
        })
        .catch((e) => console.error(e.response));
    },
    getGift({ commit }, payload) {
      commit("setIsGiftDataSend", payload);
    },
    getIsRestartGame({ commit }, payload) {
      commit("setIsRestartGame", payload);
    },
  },
};
