<template>
  <div class="content-container" id="psychologist-start">
    <div class="content">
      <div class="psychologist-opinion" id="article">
        <div class="psychologist-opinion__preview">
          <h1 class="title d-sm-none">
            Психологические особенности восприятия, и при чем тут ванная комната
          </h1>
          <div class="psychologist-opinion__preview-author">
            <img src="@/assets/png/author-of-article.png" alt="">
            <div class="psychologist-opinion__preview-author-texts">
              <h4>
                Новоселова Елена Анатольевна
              </h4>
              <p>
                психолог, член Российской психотерапевтической лиги
              </p>
            </div>
          </div>
          <div class="psychologist-opinion__preview-text">
            <h1 class="title d-none d-sm-block">
              Психологические особенности восприятия, и при чем тут ванная комната
            </h1>
            <p>
              <i>
                Бывало ли с вами, что после довольно тяжелого дня вы заходите в ванную,
                чтобы помыть руки, и абсолютно нейтральный запах мыла в секунду выводит вас из себя?
                А ведь вы пользовались этим мылом вот уже несколько недель совершенно машинально,
                не обращая на его запах никакого внимания. Вроде мелочь, но последняя
                капля, способная довести до разбитого вдребезги зеркала или слез.
                Но не спешите думать, что с вами что-то не так.
              </i>
            </p>
            <h4>
              Предопределено веками
            </h4>
            <p class="d-none d-md-block">
              В мозг человека постоянно поступает информация через пять основных
              органов чувств. Визуальный образ реальности передают рецепторы
              и нейромедиаторы глаза; звуки, тональности, вибрации мы
              ощущаем с помощью органов слуха; через рецепторы, расположенные
              на коже, ощущаем прикосновения, температуру, движение воздуха; вкусы
              и запахи распознаем через соответствующие органы.
            </p>
          </div>
        </div>
        <div class="psychologist-opinion__content">
          <p class="d-md-none">
            В мозг человека постоянно поступает информация через пять основных
            органов чувств. Визуальный образ реальности передают рецепторы
            и нейромедиаторы глаза; звуки, тональности, вибрации мы
            ощущаем с помощью органов слуха; через рецепторы, расположенные
            на коже, ощущаем прикосновения, температуру, движение воздуха; вкусы
            и запахи распознаем через соответствующие органы.
          </p>
          <p>
            Однако, распознавание окружающей среды и выбор оптимального способа
            взаимодействия у людей разные.
            <br>
            Реакция органов чувств на события окружающего мира часто зависит от того,
            какой из каналов восприятия является ведущим. Это во многом зависит
            от географического места рождения и обусловлено генетически.
          </p>
          <b-collapse id="psychologist-opinion-hidden">
            <p>
              Так например, люди, родившиеся в северных широтах (или имеющие
              там генетические корни), более склонны воспринимать
              окружающий мир через рецепторы кожи. Эволюционно на севере выживание
              и удовлетворение первичных потребностей в тепле были решающими.
              Отсюда и предпочтение ощущений кожного покрова, который либо
              передает сигнал угрозы, либо сообщает мозгу информацию, что можно
              расслабиться и не концентрировать свое внимание на температуре окружающей среды.
              <br>
              <br>
              Для человека, родившегося на юге, характерен акцент на визуальное
              восприятие мира. Яркая природа, изобилие разноцветных плодов, цвет
              моря, неба, гор — все это красочное разнообразие сообщает человеку
              не только информацию о красоте природы. Наблюдение за изменением
              оттенков неба, гор и прочего предупреждает об опасности или говорит
              о благополучии. Уроженцы юга интенсивно жестикулируют, энергично
              разговаривают, поскольку стремятся передать собеседнику как можно
              больше информации и при этом активно дополняют вербальную информацию
              жестами. Ошибочно такую манеру вести разговор принимают за горячий
              темперамент. А на самом деле, так работает система приема и кодирования информации.
              <br>
              <br>
              То же самое и с аудиалами. Чаще всего, аудиальное восприятие мира связано с
              происхождением из мест, насыщенных лесными массивами. От того, как
              шумит лес, какие звуки там раздаются, зависит и безопасность, и благополучие человека.
              <br>
              <br>
              Эти примеры частично иллюстрируют одну из причин возникновения ведущего
              канала восприятия, ее эволюционно обусловленную необходимость.
              <br>
              <br>
              Отдельно следует сказать о дигитальной системе, поскольку она напрямую
              не связана с каким-либо отдельным органом чувств. А также ее формирование
              не может быть атрибутировано по географическому принципу. Люди этого
              типа являются представителями логического типа личности, они
              руководствуются в основном выстраиванием причинно-следственных
              цепочек рассуждения и логически выверенными решениями.
              Положительные эмоции и ощущения расслабленности у них
              возникают лишь от определения сознанием структурности и упорядоченности
              полученной извне информации. Таких людей на Земле очень мало, исследования говорят об 1-5%.
              <br>
              <br>
              Несмотря на то, что органов восприятия у нас пять, ведущими
              определены четыре основных способа восприятия. Вкусовые рецепторы
              не могут являть собой отдельную систему, поскольку касаются только поглощения еды или жидкости.
              <br>
            </p>
            <h4>
              Чувства, которые определяют
            </h4>
            <p>
              Итак, мы говорим о четырех типах взаимодействия с окружающим миром, которые в психологии называются репрезентативными системами. <b>Репрезентативная система</b> — это функции восприятия и переработки информации человеком. Визуальный, кинестетический, аудиальный, дигитальный типы репрезентативных систем.
              <br>
              <br>
              Конечно же, каждый здоровый человек видит, слышит, чувствует и размышляет. Каждый задействует все пять органов чувств, но ведущей системой является одна, к которой ситуативно может присоединяться как вспомогательная любая из перечисленных. Соответственно, ведущей системе восприятия носителям разных репрезентативных систем дали категориальные наименования.
              <br>
              <br>
              <b>Визуальный тип репрезентативной системы</b> — это человек, воспринимающий мир глазами в большей степени, чем другими органами чувств, создающий зрительные образы и запоминающий информацию через них.
              Таких людей примерно 60%.
              <br>
              <br>
              Определить <b>визуала</b> можно по следующим признакам:
            </p>
            <ul>
              <li>
                <p>
                  Речь энергична, сопровождается активной жестикуляцией.
                </p>
              </li>
              <li>
                <p>
                  В словарном активе чаще используются глаголы и предикаты, относящиеся к визуальному ряду: «Мне это видится так…», «Я вижу смысл в вашем предложении», «Посмотрите, как удобно…».
                </p>
              </li>
              <li>
                <p>
                  В одежде визуала вы непременно обнаружите детали, которые требуют периодического обращения к зеркалу: шейный платок, галстук, воротник.
                </p>
              </li>
              <li>
                <p>
                  Женщина предпочтет высокий каблук, даже если это будет ей не очень удобно. А мужчина — узкие туфли кроссовкам.
                </p>
              </li>
              <li>
                <p>
                  Предпочтение «как это смотрится» превалирует над «насколько это удобно».
                </p>
              </li>
            </ul>
            <p>
              <b>Аудиалы</b> склонны воспринимать и запоминать информацию через звуковые впечатления. Тональность и экспрессия голоса собеседника для них важнее, чем логика рассуждения. Именно звуковые вибрации вызовут наибольшие эмоции, а значит, и определят отношение к тому или иному объекту. Принято считать, что таких людей не более 10-15%.
              <br>
              <br>
              Распознать <b>аудиала</b> можно по следующим признакам:
            </p>
            <ul>
              <li>
                <p>
                  Во время коммуникации голова повернута в полупрофиль.
                </p>
              </li>
              <li>
                <p>
                  Уходит от зрительного контакта.
                </p>
              </li>
              <li>
                <p>
                  Богатая лексика и эмоциональное тонирование речи.
                </p>
              </li>
              <li>
                <p>
                  В разговоре использует следующие конструкции: «Звучит похожим на правду», «Это навевает приятные мысли», «Звучит убедительно».
                </p>
              </li>
              <li>
                <p>
                  Во внешнем облике и в одежде ничем особенным не выделяется.
                </p>
              </li>
            </ul>
            <p>
              <b>Кинестетический тип личности</b> — человек, воспринимающий мир через ощущения. Для него состояние телесного комфорта, ощущения кожи, запахов, температура окружающей среды являются решающими в определении реакций — расслабляться или готовиться к напряжению. Таких людей не менее 30%. Очень часто кинестетическая репрезентативная система сочетается с визуальной, ситуативно замещая одна другую.
              <br>
              <br>
              Определить <b>кинестетика</b> можно по следующим признакам:
            </p>
            <ul>
              <li>
                <p>
                  Тело расслаблено, его поза сообщает зрителю об удобстве.
                </p>
              </li>
              <li>
                <p>
                  При жестикуляции руки расположены в основном внизу.
                </p>
              </li>
              <li>
                <p>
                  Стремится дотронуться до поверхности того или иного предмета, как бы исследуя окружающий мир через ощущения пальцев рук.
                </p>
              </li>
              <li>
                <p>
                  В одежде предпочитает удобство, всегда одеты по погоде.
                </p>
              </li>
              <li>
                <p>
                  Речь замедленная и с паузами, при этом может крутить что-нибудь в руках.
                </p>
              </li>
              <li>
                <p>
                  Говорит содержательно и вдумчиво.
                </p>
              </li>
              <li>
                <p>
                  В разговоре использует конструкции: «Я чувствую, что вы правы», «В ваших словах ощущается истина», «Чувствую, это понравится моей жене».
                </p>
              </li>
            </ul>
            <p>
              Носитель <b>дигитальной системы восприятия</b> — чистый логик. Информация поступает к нему, как и ко всем людям, через все органы чувств. Но ведущей системы через чувства у него нет. Он все пропускает через механизм выстраивания логических и причинно-следственных связей. Даже понятия эстетической привлекательности обязательно пройдут этот фильтр сознания.
              <br>
              <br>
              Распознать <b>дигитала</b> можно лишь по принципу рациональности, главенствующему в его одежде и в процессе беседы. Сколь красивой ни была бы картина, но если там присутствует деталь, нарушающая логический строй, дигитал не испытает приятных эмоций.
              <br>
              <br>
              Как следует из разбора особенностей каждой репрезентативной системы,
              они играют важнейшую роль в нашей жизни, определяя настроение, выбор,
              ощущения счастья или его отсутствия. Это касается всех аспектов жизни
              человека: от самых незначительных бытовых вещей до самых возвышенных идей и планов.
            </p>
            <h4>
              Чувства, которые создают
            </h4>
            <p>
              И конечно же, репрезентативные системы играют решающую роль в вопросах обустройства дома, интерьера, мест, где человек предполагает укрыться от бурной жизни и почувствовать себя расслабленным и самим собой.
              <br>
              <br>
              Особенно это касается обустройства пространства ванной комнаты, самого интимного места нашего жилища. Именно в ванной комнате мы остаемся наедине с собой, «снимаем маску», которую носили весь день. Как только вы перестаете себя контролировать, чувства обостряются.
              <br>
              <br>
              Мы привыкли думать, что место отдыха — это спальня, возможно, гостиная.
              Лишь немногие используют ванную комнату как пространство для релаксации,
              принимая ванны, практикуя растирания, самомассаж или другие уходовые процедуры.
              И совсем единицы задумываются о том, что само пространство ванной —
              то, как оно организовано, как освещено, как оборудовано, как выглядит —
              может быть фактором стресса или, напротив, <b>стать тихой гаванью,
              в которой набираешься энергии на день и «смываешь» его, придя домой.</b>
              <br>
              <br>
              Когда тело и сознание стремятся к расслаблению, важна каждая деталь:
              цвет, свет, расположение предметов. Мозг сканирует каждую мелочь и
              либо дает сигнал в органы чувств о снятии напряжения, либо наращивает
              возбуждение. <b>Ванная комната — это недооцененное место,</b>
              где сконцентрированы все основные возможности воздействия на наше
              состояние. И каким оно будет — зависит от того, насколько мы
              осознаем воздействие этих факторов на эмоциональный статус.
              <br>
              <br>
              Более того, ванная комната, правильно организованная с учетом ваших
              индивидуальных потребностей восприятия, становится тренажером
              позитивных эмоций и дает возможность зарядиться энергией на
              целый день, воздействуя на органы чувств в обход сознания. <br>
              Знание своей ведущей репрезентативной системы поможет минимизировать
              факторы стресса в существующем обустройстве ванной комнаты или
              полностью исключить их на этапе проектирования и ремонта.
            </p>
          </b-collapse>
          <div
            v-b-toggle="'psychologist-opinion-hidden'"
            class="psychologist-opinion__show-more button-link _psychologist-opinion-show-more"
            @click="clickToggleBtn"
          >
            <span>
              Читать далее
            </span>
            <span>
              Скрыть
            </span>
            <span>&gt;</span>
          </div>
          <div class="psychologist-opinion__test-btn">
            <div class="button">
              Узнайте свой тип!
            </div>
            <router-link to="/test" class="button button-green">
              пройти тест
            </router-link>
          </div>
          <div class="psychologist-opinion__recomendation" id="recommendations">
            <h2 class="title">
              РЕКОМЕНДАЦИИ по типу репрезентативной системы
            </h2>
            <div class="row">
              <div
                v-for="recommendation, type in recommendations"
                :key="type"
                :id="type"
                class="anchor col-12 d-flex flex-column mb-sm-4 col-sm-6"
              >
                <h3>
                  {{ recommendation.title }}
                </h3>
                <b-carousel controls :interval="10000000">
                  <b-carousel-slide v-for="slide, slideIdx in recommendation.slides" :key="slideIdx">
                    <img :src="require(`@/assets/png/${slide.img}.png`)" alt="">
                    <h5>
                      {{ slide.title }}
                    </h5>
                    <p>
                      {{ slide.text }}
                    </p>
                  </b-carousel-slide>
                </b-carousel>
              </div>
            </div>
          </div>
          <a
            href="https://leroymerlin.ru/uslugi/proektirovanie-i-dizayn/sozdayte-vannuyu-komnatu-svoey-mechty/?utm_source=vannaya-v-detalyah.ru&utm_medium=referral&utm_campaign=omd_pr_project_vannaya_august_23"
            target="_blank"
            class="button button-green ml-auto mr-auto psychologist-opinion__end-link"
          >
            спроектировать свою ванную комнату
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RECOMMENDATIONS_BY_TYPE } from '@/consts/test.js'
import { mapState, mapMutations, mapGetter, mapActions } from "vuex"

export default {
  name: 'PsychologistOpinion',

  data() {
    return {
      recommendations: RECOMMENDATIONS_BY_TYPE
    };
  },

  computed: {
    ...mapState({
      testResult: (state) => state.testResult,
    }),
  },

  mounted(){
    if (this.testResult){
      let ele = document.getElementById(`${ this.testResult }`)

      ele.scrollIntoView()
    }
  },

  methods:
  {
    clickToggleBtn(e)
    {
      if(e.target.closest('._psychologist-opinion-show-more').classList.contains('collapsed'))
        return;

      document.querySelector('#app').scrollTo({
        top: document.querySelector('#article').offsetTop,
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style scoped lang="scss">
  .content-container {
    background-color: #F5F5F5;
  }
  .psychologist-opinion {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $dark-text;

    b {
      font-weight: 500;
    }

    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 48px;
    }

    h4 {
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      margin: 0 0 20px;
    }

    h5 {
      font-style: italic;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 8px;
    }

    a:not(.button) {
      color: $green;
    }

    .title {
      padding-top: 20px;
      margin-bottom: 30px;
    }
  }
  .psychologist-opinion__content {
    margin-bottom: 66px;

    p {
      margin-bottom: 20px;
    }

    ul {
      padding-left: 20px;
      margin-bottom: 20px;

      p {
        margin-bottom: 4px;
      }
    }
  }
  .psychologist-opinion__preview {
    display: flex;

    .title {
      font-size: 36px;
      line-height: 42px;
    }

    p {
      margin-bottom: 20px;
    }
  }
  .psychologist-opinion__preview-author {
    position: relative;
    height: 328px;
    width: 219px;
    min-width: 219px;
    margin-right: 64px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .psychologist-opinion__preview-author-texts {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    left: 0;
    bottom: 0;
    min-height: 50%;
    width: 100%;
    padding: 10px;
    background: linear-gradient(0deg, #313131 0%, rgba(49, 49, 49, 0.0104167) 99.99%, rgba(90, 176, 49, 0) 100%);
    color: $white;

    h4 {
      font-weight: 700;
      font-size: 21px;
      line-height: 25px;
      text-transform: uppercase;
      margin-bottom: 3px;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0;
    }
  }
  .psychologist-opinion__test-btn {
    display: flex;
    justify-content: center;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.1));
    margin-bottom: 66px;

    & > div {
      background-color: $white;
      color: $dark-text;
      cursor: default;
    }
  }
  .psychologist-opinion__recomendation {
    .title {
      margin-bottom: 43px;
    }
  }
  .psychologist-opinion__recomendation {
    h3 {
      color: $green;
      margin-bottom: 7px;
    }

    p {
      font-size: 14px;
      line-height: 18px;
    }

    img {
      height: 270px;
      width: 100%;
      object-fit: cover;
      margin-bottom: 18px;
    }

    &::v-deep(.carousel-caption) {
      position: static;
      color: $dark-text;
      text-align: left;
      padding-top: 0;
    }

    &::v-deep(.carousel-control-prev), &::v-deep(.carousel-control-next) {
      background-color: $green;
      height: 27px;
      width: 27px;
      border-radius: 50%;
      background-image: url(@/assets/svg/icons/down-arrow.svg);
      background-repeat: no-repeat;
      background-position: center center;
      top: 130px;
      opacity: 1;
      margin-left: 12px;
      margin-right: 12px;

      .carousel-control-prev-icon, .carousel-control-next-icon {
        display: none;
      }

      &:hover {
        opacity: .86;
      }
    }

    &::v-deep(.carousel-control-prev) {
      transform: rotate(90deg);
    }
    &::v-deep(.carousel-control-next) {
      transform: rotate(-90deg);
    }
  }
  .psychologist-opinion__show-more {
    position: relative;
    display: inline-block;
    margin-bottom: 38px;

    &.not-collapsed {
      span:nth-child(1) {
        display: none;
      }
      span:nth-child(2) {
        display: inline-block;
      }

      span:nth-child(3) {
        transform: rotate(-90deg);
      }
    }

    span:nth-child(2) {
      display: none;
    }

    span:nth-child(3) {
      position: absolute;
      transform: rotate(90deg);
      right: -17px;
      transition: .2s ease;
    }
  }
  @media (max-width: 999px) {
    .psychologist-opinion {
      padding-top: 110px;
    }
  }
  @media (max-width: 999px) {
    .psychologist-opinion {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  @media (max-width: 768px) {
    .psychologist-opinion__preview {
      .title {
        font-size: 26px;
        line-height: 30px;
        padding-top: 0;
        margin-bottom: 20px;
      }
    }
    .psychologist-opinion__preview-author {
      margin-right: 10px;
      margin-bottom: 28px;
    }
    .psychologist-opinion__test-btn {
      margin-top: 22px;
      margin-bottom: 50px;

      .button {
        font-size: 21px;
        padding: 20px 30px;
        white-space: nowrap;
      }
    }

    .psychologist-opinion__end-link {
      white-space: nowrap;
      padding: 15px 25px;
      font-size: 18px;
    }
  }
  @media (max-width: 575px) {
    .psychologist-opinion {
      font-size: 14px;
      line-height: 18px;
    }

    .psychologist-opinion__preview {
      display: block;
    }

    .psychologist-opinion__preview-author {
      height: 328px;
      width: 100%;
      margin-right: 0;

      img {
        object-position: 0 20%;
      }
    }

    .psychologist-opinion__preview-author-texts {
      h4 {
        font-size: 24px;
        line-height: 25px;
      }

      p {
        width: 70%;
      }
    }
    .psychologist-opinion__recomendation {

      .title {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 18px;
      }

      img {
        height: auto;
      }

      p {
        font-size: 12px;
        line-height: 16px;
      }

      &::v-deep(.carousel-caption) {
        padding-bottom: 30px;
      }

      &::v-deep(.carousel-control-prev), &::v-deep(.carousel-control-next) {
        top: 100px;
      }
    }

    .psychologist-opinion__show-more {
      font-size: 21px;
      margin-bottom: 22px;
    }
  }
  @media (max-width: 420px) {
    .psychologist-opinion {
      padding-top: 36px;
    }
  }


.anchor {
  padding-top: 100px;
  margin-top: -100px;
}
</style>
