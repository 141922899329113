<template>
  <div class="play-banner">
    <ol>
      <li>
        Играйте
      </li>
      <li>
        Находите секретные элементы
      </li>
      <li>
        Выигрывайте подарки от Леруа Мерлен
      </li>
    </ol>
    <router-link to="/" class="button button-green">
      Играть
    </router-link>
  </div>
</template>

<script>
  export default {};
</script>

<style scoped lang="scss">
  .play-banner {
    position: relative;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(10px);
    border-radius: 2px;
    padding: 29px 19px 51px 18px;
    max-width: 456px;
    margin-bottom: 45px;

    ol {
      list-style: decimal;
      padding: 0 0 0 24px;
      margin-bottom: 0;
    }

    li {
      font-weight: 900;
      font-size: 24px;
      line-height: 48px;
      color: $white;
    }

    .button {
      position: absolute;
      left: 50%;
      bottom: -30px;
      transform: translateX(-50%);
    }
  }

  @media (max-width: 768px) {
    .play-banner {

      li {
        font-size: 21px;
        line-height: 36px;
      }

      .button {
        bottom: -15px;
      }
    }
  }
  @media (max-width: 489px) {
    .play-banner {
      padding: 12px 16px 46px 16px;
    }
  }
</style>
