<template>
  <div class="content-container popit">
    <div
      :class="['popit__bg', { 'm-finish': isGameFinished }]"
      :style="isGameFinished ? selectedBackground : ''"
    ></div>
    <div class="popit__content">
      <h1 class="popit__title title">Интерьерный поп-ит</h1>
      <div class="popit__game">
        <popit-game
          :items="items"
          :background="BACKGROUNDS[currentBackground - 1].base"
          :is-game-finished="isGameFinished"
          @change="onPopitChange"
        />
      </div>
      <div :class="['popit__buttons', { 'm-hide': isGameFinished }]">
        <button class="button m-gray" @click="onRestartClick">
          начать заново
        </button>
        <button class="button m-gray" @click="onBackgroundChange">
          Выбрать другой дизайн
        </button>
      </div>
      <Transition>
        <button
          class="popit__restart-game button button-green"
          @click="onStartGameAgain"
          v-if="isRestartGame && isGameFinished"
        >
          начать игру заново
        </button>
      </Transition>
      <Transition>
        <popit-prize
          v-if="isWinner && !isGiftDataSend"
          class="popit__prize"
          @click="onPrizeClick"
        />
      </Transition>
    </div>
  </div>
</template>

<script>
import PopitGame from "@/components/games/popit/PopitGame";
import { BACKGROUNDS } from "@/consts/popit";
import audio from "@/assets/audio/pop.mp3";
import Gift from "@/components/Gift";
import PopitPrize from "@/components/PopitPrize";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "Popit",
  components: { PopitPrize, Gift, PopitGame },
  data() {
    return {
      BACKGROUNDS,
      audio,
      height: 0,
      width: 0,
    };
  },
  mounted() {
    this.initGame();
    this.$bvModal.show('popit-modal')
    this.updateSizes();
    window.addEventListener("resize", this.updateSizes);
  },
  methods: {
    ...mapActions("popitModule", [
      "initGame",
      "restartGame",
      "changeGame",
      "changeBackground",
      "finishGame",
    ]),
    onRestartClick() {
      this.restartGame();
    },
    onBackgroundChange() {
      this.restartGame();
      this.changeBackground();
    },
    onPopitChange() {
      this.changeGame(this.items.every((item) => item.isActive === false));

      if (this.isGameFinished) {
        setTimeout(() => {
          this.isWinner
            ? this.$bvModal.show("prize-modal")
            : this.$bvModal.show("popit-win-modal");
        }, 5000);
      }
    },
    onPrizeClick() {
      this.$bvModal.show("prize-modal");
    },
    onStartGameAgain() {
      this.restartGame();
    },
    updateSizes() {
      this.height = window.innerHeight;
      this.width = window.innerWidth;
    },
  },
  computed: {
    ...mapState("popitModule", {
      items: (state) => state.items,
      isGameFinished: (state) => state.isGameFinished,
      currentBackground: (state) => state.currentBackground,
      isWinner: (state) => state.isWinner,
      isGiftDataSend: (state) => state.isGiftDataSend,
      isRestartGame: (state) => state.isRestartGame,
    }),
    ...mapGetters("popitModule", {
      isGameAlmostFinished: "isGameAlmostFinished",
    }),
    isSpecialSize() {
      return this.width == 1440 && this.height >= 900
    },
    selectedBackground() {
      const finish = BACKGROUNDS[this.currentBackground - 1].finish;
      const height = BACKGROUNDS[this.currentBackground - 1].height;

      if (this.isSpecialSize) {
        return {
          backgroundImage: `url(${
            height
          })`,
        };
      } else {
        return {
          backgroundImage: `url(${
            this.height <= 900 && this.width >= 1000 ? height : finish
          })`,
        };
      }
    },
  },
  watch: {
    isGameAlmostFinished() {
      if (this.isGameAlmostFinished) {
        this.finishGame();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.popit {
  height: 100%;
  width: 100%;
  max-height: 100vh;
  position: relative;
  z-index: 1;
  padding-top: 50px;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("@/assets/png/home-bg@1920.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
    height: 100%;
    width: 100%;
    max-height: 100vh;
    backdrop-filter: blur(20px);
    filter: blur(8px);
    z-index: 0;
    transition: filter 1s 3s, background-image 0s 2s;

    @media (max-width: 1500px) {
      background-image: url("@/assets/png/home-bg.png");
    }

    @media (max-width: 999px) {
      background-position: center;
      background-image: url("@/assets/png/home-bg-mob.png");
    }

    &.m-finish {
      filter: none;
    }
  }

  &__content {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @media (max-width: 999px) {
      padding-top: 175px;
      grid-template-rows: 1fr auto;
    }

    @media (max-width: 499px) {
      padding-top: 56px;
    }
  }

  &__restart-game {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__title {
    color: $white;
    margin-left: auto;
    margin-right: 80px;
    margin-bottom: 36px;
    z-index: 1;
    width: 100%;
    text-align: right;

    @media (max-width: 999px) {
      display: none;
    }
  }

  &__game {
    margin-bottom: 41px;
    height: 100%;
    display: flex;
    align-items: center;

    @media (max-width: 1439px) {
      margin-bottom: 21px;
    }
  }

  &__buttons {
    margin-top: auto;
    display: flex;
    gap: 35px;
    justify-content: center;
    margin-bottom: 20px;
    opacity: 1;
    transition: opacity 0.5s;

    &.m-hide {
      opacity: 0;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
