<template>
  <div class="banner-with-logo">
    <transition name="fade">
      <h2>
        Ванная
        <br>
        в деталях

        <p class="subtext">
          Намечтайте идеальную <br>
          ванную и выиграйте <br>
          подарки
        </p>
      </h2>
    </transition>
    <!-- <transition name="fade">
      <h2>
        <slot name="second" />
      </h2>
    </transition> -->

    <img src="@/assets/svg/icons/banner-logo.svg" alt="">
  </div>
</template>

<script>
  export default {
    name: 'BannerWithLogo',
    props: {
      animated: {
        type: Boolean,
        default: false,
      },
      timeout: {
        type: Number,
        default: 3000000
      }
    },
    data() {
      return {
        showDefault: true,
        interval: null
      };
    },
    methods: {
      initIterval() {
        this.interval = setInterval(() => {
          this.showDefault = !this.showDefault;
        }, this.timeout);
      },
      stopInterval() {
        clearInterval(this.interval);
      }
    },
    mounted() {
      if (this.animated)
        this.initIterval();
    },
    destroyed() {
      this.stopInterval();
    },
  };
</script>

<style scoped lang="scss">
  .banner-with-logo {
    position: absolute;
    left: 265px;
    bottom: 0;
    padding: 0;
    max-width: 577px;
    width: 100%;
    min-height: 316px;

    @media (min-width: 999px) and (max-height: 800px){
      left: 117px !important;
    }

    @media(max-width: 1500px){
      left: 187px;
    }

    @media(max-width: 999px){
      left: 0;
      bottom: 90px;
    }

    .subtext {
      font-family: $din-display;
      font-weight: 700;
      font-size: 20px;
      line-height: 100%;
      margin-top: 25px;
      margin-bottom: 0;
    }

    h2 {
      position: absolute;
      left: 33px;
      top: 40px;
      font-family: $din-display;
      font-weight: 700;
      font-size: 58px;
      line-height: 76%;
      color: $white;

      &::v-deep(span) {
        position: relative;
        padding: 0 4px;
        margin-left: 4px;

        & > span {
          color: $green;
          z-index: 2;
          padding: 0;
          margin-left: 0;

          &:before {
            display: none;
          }
        }

        &:before {
          content: "";
          position: absolute;
          background-color: $white;
          left: 0;
          top: 3px;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .banner-with-logo {
      min-height: 263px;

      h2 {
        font-size: 40px;
        line-height: 49px;
      }

    }
  }
  @media (max-width: 489px) {
    .banner-with-logo {

      h2 {
        left: 31px;
        top: 13px;

        &::v-deep(span) {
          margin-left: 3px;
        }
      }
    }
  }
</style>
