<template>
  <button
    @click="onPopitClick"
    :class="['popit-item', { 'm-disable': !isActiveLocal }]"
  ></button>
</template>

<script>
import audio from "@/assets/audio/pop.mp3";

export default {
  name: "PopitItem",
  props: {
    id: {
      type: Number,
      default: 0,
    },
    isActive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      audio,
      isActiveLocal: true,
    };
  },
  methods: {
    onPopitClick() {
      this.isActiveLocal = false;
      // const audio = new Audio(this.audio);
      // audio.play();
      this.$emit("click", this.id);
    },
  },
  watch: {
    isActive() {
      this.isActiveLocal = this.isActive;
    },
  },
};
</script>

<style scoped lang="scss">
.popit-item {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #44981c;
  background-image: url("@/assets/svg/games/popit.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: none;
  opacity: 1;
  transition: opacity 0.1s;
  will-change: opacity;

  &:active,
  &:focus {
    &:before {
      opacity: 1;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("@/assets/svg/games/popit-pushed.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0;
    transition: opacity 0.1s;
    will-change: opacity;
  }

  &.m-disable {
    cursor: default;
    opacity: 0;
    //
    //&:before {
    //  opacity: 1;
    //}
  }
}
</style>
