<template>
  <div>
    <b-modal
      id="mirror-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
      @hide="onHide"
    >
      <span class="close-icon" @click="$bvModal.hide('mirror-modal')">
        <img
          src="@/assets/svg/icons/close.svg"
          aria-label="close"
          alt="Закрыть"
        />
      </span>

      <div class="d-flex flex-column align-items-center">
        <h1 class="title">Арт-зеркало</h1>

        <p class="modal-text">
          Рисуйте на запотевшем зеркале и подбирайте для себя <br />
          зеркало мечты в Леруа Мерлен
        </p>

        <div>
          <button class="button button-green" @click="onStartClick">
            начать
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("mirrorModule", ["startGame"]),
    onStartClick() {
      try {
        ym(90102394, "reachGoal", "start_game");
        _txq.push(["track", "start"]);
        _tmr.push({ type: "reachGoal", id: 3385609, goal: "Start2" });
      } catch (e) {
        console.log("analytics error => ", e);
      }

      this.$bvModal.hide("mirror-modal");
      this.startGame();
    },
    onHide() {
      this.onStartClick();
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 42px;
  text-align: center;
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
  transition: 0.5s all;

  &:hover {
    opacity: 0.8;
  }
}

.button {
  width: 100%;
  max-width: 120px;
  margin-top: 20px;
}

.modal-text {
  @media (max-width: 768px) {
    br {
      display: none;
    }
  }
}
</style>
