<template>
  <div>
    <b-modal
      id="test-result"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <span class="close-icon"
            @click="redirect">
        <img src="@/assets/svg/icons/close.svg"
             aria-label="close">
      </span>

      <div class="d-flex flex-column align-items-center test-result__wr">
        <h1 class="title">
          {{ result.title }}
        </h1>

        <p class="modal-text">
          {{ result.text }}
        </p>

        <button class="button button-green"
                @click="redirect">
          Смотреть подходящие для вас дизайн-проекты
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { TEST_RESULTS } from '@/consts/test.js'
import { mapState, mapMutations, mapGetter, mapActions } from "vuex"

export default {
	name: 'TestResult',
	props: {
		type: String,
		default: '',
	},

  computed: {
    result() {
      if (this.type) {
        return TEST_RESULTS[this.type];
      } else {
        return { title: '', text: '' };
      }
    }
  },

  methods: {
    ...mapMutations( [
      'setTestResult',
    ]),

    redirect() {
      this.setTestResult(this.type)
      this.$router.push('/psychologist-page#recommendations');
    },
  }
};
</script>

<style scoped lang="scss">
.test-result__wr {
  margin-bottom: 40px;
}
.title {
  font-size: 42px;
  text-align: center;
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
  transition: .5s all;

  img {
    width: 100%;
  }

  &:hover {
    opacity: .8;
  }
}

.button {
  width: fit-content;
  margin-top: 20px;
  padding: 18px 20px;
}
@media (max-width: 768px) {
  .title {
    font-size: 30px;
    line-height: 28px;
    max-width: 370px;
  }

  .test-result__wr {
    margin-bottom: 0;
  }
}
@media (max-width: 425px) {
  .close-icon {
    height: 16px;
    width: 16px;
    margin-right: 5%;
    margin-top: -2px;
  }
}
</style>
