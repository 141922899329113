<template>
  <div class="content-container position-relative" id="promo-start">
    <div class="content">
      <div class="promo__banner">
        <!-- <PlayBanner /> -->
      </div>

      <PromoMechanics />

      <PromoWinners />

      <PromoFaq />
    </div>

    <div class="banner-wrapper">
      <BannerWithLogo animated>
        Ванная
        <br>
        в деталях

        <h3>
          Намечтайте идеальную <br>
          ванную и выиграйте <br>
          подарки
        </h3>

        <!-- <template v-slot:second>
          Намечтайте идеальную <br>
          ванную и выиграйте <br>
          подарки
        </template> -->
      </BannerWithLogo>

      <router-link to="/" class="button-play button button-green">Играть</router-link>
    </div>
  </div>
</template>

<script>
import PlayBanner from '@/components/PlayBanner.vue';
import BannerWithLogo from '@/components/BannerWithLogo.vue';
import PromoMechanics from '@/components/promo/PromoMechanics.vue';
import PromoWinners from '@/components/promo/PromoWinners.vue';
import PromoFaq from '@/components/promo/PromoFaq.vue';

export default {
  name: 'Promo',
  components: {
    PlayBanner,
    BannerWithLogo,
    PromoMechanics,
    PromoWinners,
    PromoFaq,
  },
};
</script>

<style scoped lang="scss">
  .promo__banner {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 92px;
    margin-bottom: 100px;
    min-height: 100vh;

    &:before {
      content: "";
      position: absolute;
      background-image: url(@/assets/png/promo-bg.png);
      background-repeat: no-repeat;
      height: 100%;
      width: 100vw;
      left: calc( -1 * ( ( 100vw - 360px - 910px ) / 2 + 360px ) );
      top: 0;
      background-size: cover;
    }

    &::v-deep(.play-banner) {
      margin-left: auto;
      margin-right: -30px;
    }

    &::v-deep(.banner-with-logo) {
      margin-top: auto;
    }
  }
  .content-container {
    padding-top: 0;
    background-color: #F5F5F5;
  }

  @media(max-width: 1500px) {
    .promo__banner {
      &:before {
        left: calc( -1 * ( ( 100vw - 310px - 910px ) / 2 + 310px ) );
      }
    }
  }
  @media(max-width: 1150px) {
    .promo__banner {
      &::v-deep(.play-banner) {
        margin-right: 0;
      }

      &::v-deep(.banner-with-logo) {
        margin-left: 0;
      }
    }
  }
  @media(max-width: 999px) {
    .promo__banner {
      min-height: 90vh;

      &:before {
        left: calc( -1 * ( ( 100vw - 910px ) / 2 ) );
      }
    }
  }
  @media(max-width: 970px) {
    .promo__banner {
      padding-top: 106px;
    }
  }
  @media(max-width: 910px) {
    .promo__banner {
      &:before {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  @media(max-width: 768px) {
    .promo__banner {
      align-items: center;
      padding-left: 12px;
      padding-right: 12px;

      &:before {
        background-position: 60% 50%;
      }

      &::v-deep(.play-banner), &::v-deep(.banner-with-logo) {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }

      &::v-deep(.play-banner) {
        margin-bottom: 103px;
        max-width: 577px;
      }

      &::v-deep(.banner-with-logo) {
        margin-bottom: 8px;
      }
    }
  }
  @media(max-width: 489px) {
    .promo__banner {
      &:before {
        background-image: url(@/assets/png/promo-bg-mobile.png);
      }
    }
  }

.banner-wrapper {
  height: 100vh;
  width: 100vw;
  position: absolute;
}

.button-play {
  position: absolute;
  right: 21.5%;
  bottom: 50px;
  width: 110px;
  height: 61px;

  @media(max-width: 576px){
    height: 51px;
    font-size: 18px;
    bottom: 425px;
    right: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
