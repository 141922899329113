import api from "@/api";
import { BACKGROUNDS } from "@/consts/mirror";

export const mirrorModule = {
  state: () => ({
    isGameStart: false,
    isGameFinished: false,
    isWinner: false,
    isGiftDataSend: false,
    isRestartGame: false,
    currentBackground: 1,
    renderFigureCount: 0,
    finishGameData: {},
  }),
  mutations: {
    setIsGameStart(state, payload) {
      state.isGameStart = payload;
    },
    setCurrentBackground(state, payload) {
      state.currentBackground = payload;
    },
    setIsGiftDataSend(state, payload) {
      state.isGiftDataSend = payload;
    },
    setFinishGameData(state, payload) {
      state.finishGameData = payload;
    },
    setIsWinner(state, payload) {
      state.isWinner = payload;
    },
    setIsGameFinished(state, payload) {
      state.isGameFinished = payload;
    },
    setRenderFigureCount(state, payload) {
      state.renderFigureCount = payload;
    },
    incrementRenderFigureCount (state, n) {
      state.renderFigureCount += n
    },
    setIsRestartGame(state, payload) {
      state.isRestartGame = payload;
    },
  },
  getters: {
    isGameAlmostFinished(state) {},
  },
  actions: {
    restartGame({ commit }) {
      commit("setIsGameFinished", false);
      commit("setIsRestartGame", false);
      commit("incrementRenderFigureCount", 1);
    },
    startGame({ commit, dispatch }) {
      dispatch('restartGame')
      commit("setIsGameStart", true);
    },
    finishGame({ commit }) {
      commit("setIsGameFinished", true);

      return api.game
        .getPlayerInfo({
          type: "mirror",
        })
        .then((res) => {
          commit("setFinishGameData", res.data.data);
          commit("setIsWinner", res.data.data.won);
        })
        .catch((e) => console.error(e.response));
    },
    changeBackground({ commit, state }) {
      state.currentBackground < BACKGROUNDS.length
        ? commit("setCurrentBackground", state.currentBackground + 1)
        : commit("setCurrentBackground", 1);
    },
    getGift({ commit }, payload) {
      commit("setIsGiftDataSend", payload);
    },
    getIsRestartGame({ commit }, payload) {
      commit("setIsRestartGame", payload);
    },
  },
};
