<template>
  <div>
    <b-modal
      id="prize-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
      @hide="onHide"
    >
      <span class="close-icon" @click="closeModal">
        <img
          src="@/assets/svg/icons/close.svg"
          aria-label="close"
          alt="Закрыть"
        />
      </span>

      <div class="d-flex flex-column align-items-center">
        <h1 class="title">Подарок уже ждёт Вас</h1>

        <p class="text">Заполните данные, чтобы получить его</p>

        <form ref="form" class="modal-form" @submit.stop.prevent="submit">
          <div class="inputs">
            <b-form-group
              id="surname-input"
              label-for="name-input"
              :invalid-feedback="invalidName"
            >
              <b-form-input
                id="name-input"
                placeholder="Фамилия Имя Отчество"
                required
                type="text"
                pattern="[A-Za-z]+"
                :class="{ 'is-invalid': isInvalidName }"
                v-model="$v.item.name.$model"
                :state="validateState($v.item.name)"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-for="phone-input"
              :invalid-feedback="invalidPhone"
              v-mask="'+7 (###) ###-##-##'"
            >
              <b-form-input
                id="phone-input"
                placeholder="Мобильный телефон"
                required
                v-model="$v.item.phone.$model"
                :class="{
                  'is-invalid': isInvalidPhone,
                  'is-valid': !isInvalidPhone,
                }"
                :state="validateState($v.item.phone)"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-for="email-input"
              :invalid-feedback="invalidEmail"
            >
              <b-form-input
                id="email-input"
                placeholder="E-mail"
                type="email"
                required
                v-model="$v.item.email.$model"
                :class="{
                  'is-invalid': isInvalidEmail,
                  'is-valid': !isInvalidEmail,
                }"
                :state="validateState($v.item.email)"
              ></b-form-input>
            </b-form-group>
          </div>

          <h3>Выберите тип приза:</h3>

          <div class="prizes">
            <div
              class="prize-wrapper"
              ref="family"
              @click="setPrize($event, 'family', 1)"
            >
              <img
                src="@/assets/png/gift.png"
                srcset="@/assets/png/gift.png, @/assets/png/gift@2x.png 2x"
                alt="Подарок"
              />

              <div>
                <div class="prize-title">Для Семьи</div>

                <div class="prize-description">
                  Корзина для хранения, набор банных полотенец, ступенька для
                  ребенка
                </div>
              </div>
            </div>

            <div
              class="prize-wrapper"
              ref="beauty"
              @click="setPrize($event, 'beauty', 2)"
            >
              <img
                src="@/assets/png/gift.png"
                srcset="@/assets/png/gift.png, @/assets/png/gift@2x.png 2x"
                alt="Подарок"
              />

              <div>
                <div class="prize-title">Для Красоты</div>

                <div class="prize-description">
                  Органайзер для косметики, дозатор для жидкого мыла, настольное
                  зеркало с подсветкой
                </div>
              </div>
            </div>

            <div
              class="prize-wrapper"
              ref="cozy"
              @click="setPrize($event, 'cozy', 3)"
            >
              <img
                src="@/assets/png/gift.png"
                srcset="@/assets/png/gift.png, @/assets/png/gift@2x.png 2x"
                alt="Подарок"
              />

              <div>
                <div class="prize-title">Для уюта</div>

                <div class="prize-description">
                  Ароматический диффузор, наполнитель для диффузора, коврик для
                  ванной
                </div>
              </div>
            </div>
          </div>

          <b-form-checkbox
            id="checkbox-rules"
            class="mt-3"
            v-model="$v.item.rules.$model"
            :state="validateState($v.item.rules)"
            name="rules"
          >
            Я ознакомился(-лась) с
            <a href="/rules.pdf" target="_blank"> Правилами Акции </a>
            и даю

            <a href="/policies.pdf" target="_blank"
              >согласие на обработку персональных данных</a
            >.
          </b-form-checkbox>

          <!-- <b-form-checkbox
            id="checkbox-age"
            class="mt-3 checkbox-age"
            v-model="$v.item.age.$model"
            :state="validateState($v.item.age)"
            name="age"
          >
            Я подтверждаю, что являюсь гражданином РФ, достигшим 18 лет
          </b-form-checkbox>

          <a
            href="https://leroymerlin.ru/politika-obrabotki-personalnykh-dannykh/"
            target="_blank"
            class="policies"
          >
            Политика обработки персональных данных
          </a> -->
        </form>

        <button class="button button-white" type="submit" @click="submit">
          отправить
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api/index";
import { validationMixin } from "vuelidate";
import {
  helpers,
  maxLength,
  minLength,
  required,
} from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";

const text = helpers.regex("text", /^[?!,.а-яА-ЯёЁ\s]+$/);
const email = helpers.regex("email", /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);

export default {
  mixins: [validationMixin],

  validations: {
    item: {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(40),
        text,
      },
      phone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18),
      },
      email: {
        required,
        minLength: minLength(4),
        email,
      },
      rules: {
        required,
      },
      // age: {
      //   required,
      // },
      // prize: {
      //   required,
      // },
    },
  },

  data() {
    return {
      item: {
        // age: "",
        name: "",
        phone: "",
        email: "",
        rules: "",
        prize: "",
      },
      isInvalidName: false,
      isInvalidEmail: false,
      isInvalidPhone: false,
      invalidName: "Некорректное поле",
      invalidPhone: "Некорректное поле",
      invalidEmail: "Некорректное поле",
    };
  },

  computed: {
    ...mapState("popitModule", {
      finishGameData: (state) => state.finishGameData,
      isGameFinished: (state) => state.isGameFinished,
    }),
  },

  methods: {
    ...mapActions("popitModule", ["getGift", "getIsRestartGame"]),
    resetModal() {
      this.$bvModal.hide("prize-modal");
      this.$bvModal.show("thanks-modal");

      this.$v.$reset();

      (this.item.name = ""), (this.item.email = "");
      (this.item.phone = ""),
        (this.item.rules = ""),
        (this.isInvalidName = false),
        (this.isInvalidPhone = false),
        (this.isInvalidEmail = false),
        (this.invalidName = "Некорректное поле"),
        (this.invalidPhone = "Некорректное поле"),
        (this.invalidEmail = "Некорректное поле");
    },
    closeModal() {
      this.$v.$reset();

      (this.item.name = ""), (this.item.email = "");
      (this.item.phone = ""),
        (this.item.rules = ""),
        (this.isInvalidName = false),
        (this.isInvalidPhone = false),
        (this.isInvalidEmail = false),
        (this.invalidName = "Некорректное поле"),
        (this.invalidPhone = "Некорректное поле"),
        (this.invalidEmail = "Некорректное поле");

      this.$bvModal.hide("prize-modal");
      if (this.isGameFinished) this.getIsRestartGame(true);
    },
    onHide(bvModalEvent) {
      if (
        bvModalEvent.trigger === "backdrop" ||
        bvModalEvent.trigger === "esc"
      ) {
        if (this.isGameFinished) this.getIsRestartGame(true);
      }
    },
    setPrize($event, refKey, id) {
      this.$refs["family"].classList.remove("active-prize");
      this.$refs["beauty"].classList.remove("active-prize");
      this.$refs["cozy"].classList.remove("active-prize");

      this.item.prize = id;
      this.$refs[refKey].classList.add("active-prize");
    },

    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },

    submit() {
      this.$v.item.$touch();

      if (this.$v.item.$anyError) {
        console.error("$v.item.$anyError => ", this.$v.item.$anyError);
        return false;
      }

      if (!this.item.prize) {
        this.$toast.open({
          message: "Необходимо сначала выбрать тип приза",
          type: "error",
        });
        return;
      }

      if (this.finishGameData.length === 0) {
        console.error(
          "finishGameData.length === 0 => ",
          this.finishGameData.length === 0
        );
        return;
      }

      api.game
        .getPrize({
          name: this.item.name,
          phone: this.item.phone,
          email: this.item.email,
          attempt_id: this.finishGameData.id,
          prize_id: this.item.prize,
        })
        .then(() => {
          this.resetModal();
          this.getGift(true);
          this.$bvModal.hide("prize-modal");
          this.$bvModal.show("thanks-modal");
        })
        .catch((error) => {
          console.error(error.response);
          this.getGift(false);

          if (error.response.data.errors) {
            Object.entries(error.response.data.errors).forEach((item) => {
              switch (item[0]) {
                case "name":
                  this.invalidName = item[1];
                  this.isInvalidName = true;
                  break;
                case "phone":
                  this.invalidPhone = item[1];
                  this.isInvalidPhone = true;
                  break;
                case "email":
                  this.invalidEmail = item[1];
                  this.isInvalidEmail = true;
                  break;
                case "attempt_id":
                  this.$toast.open({
                    message: "Ваш id не найден",
                    type: "error",
                  });
                  break;
              }
            });
          }

          this.$toast.open({
            message: error.response.data.message,
            type: "error",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 36px;
  color: $white;
  text-align: center;
  margin-bottom: 0;
  margin-top: 60px;

  @media (max-width: 576px) {
    font-size: 26px;
    margin-top: 80px;
  }
}

.text {
  font-family: $regular;
  color: $white;
  font-size: 21px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0;
  text-align: center;

  @media (max-width: 576px) {
    font-size: 18px;
  }
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
  transition: 0.5s all;

  &:hover {
    opacity: 0.8;
  }
}

h3 {
  font-weight: 700;
  font-family: $regular;
  color: $white;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 32px;
  line-height: 28px;
}

.button {
  margin-top: 15px;
}

.prizes {
  width: 100%;

  .prize-wrapper {
    width: 100%;
    background-color: $white;
    border-radius: 30px;
    display: flex;
    min-height: 100px;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    font-family: $regular;
    cursor: pointer;
    transition: 0.6s all;

    &:hover {
      opacity: 0.9;

      .prize-title {
        color: $green;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 576px) {
      min-height: 130px;
    }
  }

  .prize-title {
    text-transform: uppercase;
    font-size: 18px;
    color: $dark-text;
    font-weight: 700;
    line-height: 21px;
    transition: 0.6s all;
  }

  .prize-description {
    font-size: 14px;
    font-weight: 400;
    max-width: 90%;
  }

  img {
    max-width: 110px;
    margin-left: -20px;
    margin-top: -30px;
    margin-right: 10px;

    @media (max-width: 576px) {
      max-width: 90px;
      margin-left: 0;
      margin-top: 0;
    }
  }
}

.active-prize {
  .prize-title {
    color: $green;
  }
}
</style>
