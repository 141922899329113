<template>
  <div class="promo-mechanics" id="mechanics">
    <div class="promo-mechanics__texts">
      <h2 class="title">
        Призы и механика розыгрыша
      </h2>
      <ul>
        <li>
          <p>
            Играйте
          </p>
        </li>
        <li>
          <p>
            Находите секретные элементы
          </p>
        </li>
        <li>
          <p>
            Выигрывайте подарки от Леруа Мерлен
          </p>
        </li>
      </ul>
      <p>
        Каждый день разыгрывается 3 приза, а какой именно подарок получить — выбирать вам
      </p>
      <a href="/rules.pdf" target="_blank">
        Полные правила&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>&gt;</span>
      </a>
    </div>
    <div class="promo-mechanics__gifts">
      <Gift v-for="gift, i in gifts" :key="i" :name="gift.name" :desc="gift.desc" />
    </div>
  </div>
</template>

<script>
  import Gift from '@/components/Gift.vue';
  import { PROMO_GIFTS } from '@/consts/promo.js';
  export default {
    name: 'PromoMechanics',
    components: {
      Gift
    },
    data() {
      return {
        gifts: PROMO_GIFTS
      };
    },
  };
</script>

<style scoped lang="scss">
  .promo-mechanics {
    display: flex;

    .title {
      margin-bottom: 16px;
    }

    &::v-deep(.gift) {
      &:not(:last-child) {
        margin-bottom: 27px;
      }
    }
  }
  .promo-mechanics__gifts {
    min-width: 463px;
  }
  .promo-mechanics__texts {
    color: $dark-text;
    margin-right: 113px;

    ul {
      margin-bottom: 20px;
      padding-left: 20px;
      width: 95%;
    }

    li p {
      font-weight: 400;
      margin-bottom: 0;
    }

    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 0em;
      margin-bottom: 20px;
    }

    a {
      font-weight: 700;
      font-size: 21px;
      line-height: 21px;
      text-transform: uppercase;
      color: $green;
    }
  }
  @media (max-width: 1150px) {
    .promo-mechanics {
      padding: 0 12px;
    }

    .promo-mechanics__texts {
      margin-right: 50px;
    }
  }
  @media (max-width: 970px) {
    .promo-mechanics {
      margin-bottom: 84px;
    }
  }
  @media (max-width: 970px) {
    .promo-mechanics__texts {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
  @media (max-width: 800px) {
    .promo-mechanics {
      flex-direction: column;
    }

    .promo-mechanics__texts {
      max-width: 500px;

      p {
        font-size: 16px;
        line-height: 23px;
      }
    }

    .promo-mechanics__gifts {
      min-width: unset;
      max-width: 463px;
    }
  }
</style>
