import Vue from "vue";
import Vuex from "vuex";
import { popitModule } from "./popitModule";
import { mirrorModule } from "./mirrorModule";
import { puzzleModule } from "./puzzleModule"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    testResult: false,
  },
  getters: {},
  mutations: {
    setTestResult(state, data) {
      state.testResult = data
    },
  },
  actions: {},
  modules: {
    popitModule: {
      namespaced: true,
      ...popitModule,
    },
    puzzleModule: {
      namespaced: true,
      ...puzzleModule,
    },
    mirrorModule: {
      namespaced: true,
      ...mirrorModule,
    },
  },
});
