<template>
  <div :class="['mirror', ' content-container ', { 'm-filter': !isGameStart }]">
    <div
      :class="['mirror__bg', { 'm-long': isGameFinished }]"
      :style="selectedBackground"
    ></div>
    <div class="mirror__content">
      <h1 class="mirror__title title">Арт-зеркало</h1>
      <Transition>
        <div :class="['mirror__game', `bg-${currentBackground}`]">
          <mirror-game
            :frontImg="currentBackgroundObject.figure"
            :backImg="currentBackgroundObject.figureFill"
            :width="figureWidth"
            :height="figureHeight"
            :renderCount="renderFigureCount"
            :finishPercent="40"
            :isComplete="isGameFinished"
            :brash="brash"
            @complete="onComplete"
          />
        </div>
      </Transition>
      <div
        :class="[
          'mirror__buttons',
          { 'm-hide': !isGameStart || isGameFinished },
        ]"
      >
        <button class="button button-green" @click="onRestartGame">
          начать заново
        </button>
        <button class="button button-green" @click="onBackgroundChange">
          выбрать другое изображение
        </button>
      </div>
      <Transition>
        <button
          class="mirror__restart-game button button-green"
          @click="onStartGameAgain"
          v-if="isRestartGame"
        >
          начать игру заново
        </button>
      </Transition>
      <Transition>
        <popit-prize
          v-if="isWinner && isGiftDataSend"
          class="popit__prize"
          @click="onPrizeClick"
        />
      </Transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PopitPrize from "@/components/PopitPrize";
import MirrorGame from "@/components/games/mirror/MirrorGame";
import { BACKGROUNDS, BRASH_130 } from "@/consts/mirror";

export default {
  name: "Mirror",
  components: { PopitPrize, MirrorGame },
  data() {
    return {
      renderCount: 0,
      BACKGROUNDS,
      BRASH_130,
      width: 0,
      height: 0,
    };
  },
  created() {
    this.updateSizes();
    window.addEventListener("resize", this.updateSizes);
  },
  mounted() {
    this.$bvModal.show("mirror-modal");
  },
  computed: {
    ...mapState("mirrorModule", {
      isGameStart: (state) => state.isGameStart,
      isGameFinished: (state) => state.isGameFinished,
      isWinner: (state) => state.isWinner,
      isRestartGame: (state) => state.isRestartGame,
      isGiftDataSend: (state) => state.isGiftDataSend,
      currentBackground: (state) => state.currentBackground,
      renderFigureCount: (state) => state.renderFigureCount,
    }),
    currentBackgroundObject() {
      return BACKGROUNDS[this.currentBackground - 1];
    },
    isMinHeight() {
      return this.height <= 900 && this.width >= 1000;
    },
    isSpecialSize() {
      return this.width == 1440 && this.height == 900
    },
    isTablet() {
      return this.width <= 600;
    },
    selectedBackground() {
      let finish = this.currentBackgroundObject.finish;
      let base = this.currentBackgroundObject.base;

      if (this.isMinHeight) {
        finish = this.currentBackgroundObject.finishHeight;
      }

      if (this.isSpecialSize) {
        finish = this.currentBackgroundObject.finish;
      }

      if (this.isTablet) {
        finish = this.currentBackgroundObject.finishMob;
      }

      return {
        backgroundImage: `url(${this.isGameFinished ? finish : base})`,
      };
    },
    figureWidth() {
      return this.calcSizes(this.currentBackgroundObject.width);
    },
    figureHeight() {
      return this.calcSizes(this.currentBackgroundObject.height);
    },
    brash() {
      if (this.currentBackgroundObject.id === 5 || this.currentBackgroundObject.id === 6) {
        return this.BRASH_130;
      } else {
        return undefined;
      }
    },
  },
  methods: {
    ...mapActions("mirrorModule", [
      "changeBackground",
      "restartGame",
      "finishGame",
    ]),
    onBackgroundChange() {
      this.restartGame();
      this.changeBackground();
    },
    onRestartGame() {
      this.restartGame();
    },
    onStartGameAgain() {
      this.restartGame();
      this.changeBackground();
    },
    onPrizeClick() {
      this.$bvModal.show("prize-modal");
    },
    onComplete() {
      this.finishGame();
    },
    updateSizes() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
    calcSizes(measure) {
      let size = measure;

      if (this.isTablet) {
        size = size - (size / 100) * 30;
      }

      if (this.isMinHeight) {
        size = (size / 100) * ((this.height * 90) / 800);
      }

      return Math.round(size);
    },
  },
  watch: {
    isGameFinished() {
      if (this.isGameFinished) {
        setTimeout(() => {
          this.isWinner
            ? this.$bvModal.show("mirror-prize-modal")
            : this.$bvModal.show("mirror-win-modal");
        }, 7000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.mirror {
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 100vh;
  padding-top: 50px;
  overflow: hidden;
  filter: none;
  backdrop-filter: none;
  transition: filter 1s, background-image 1s;

  &.m-filter {
    backdrop-filter: blur(20px);
    filter: blur(8px);
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("@/assets/img/mirror/interior-1.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;
    height: 100%;
    width: 100%;
    transition: background-image 1s;

    @media (max-width: 1000px) {
      transform: translate(0, 0);
      background-position: center;
    }

    &.m-long {
      transition: background-image 1s 3s;
    }
  }

  &__content {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__title {
    position: absolute;
    top: 0;
    right: 106px;
    color: $white;
    z-index: 1;

    @media (max-width: 999px) {
      display: none;
    }
  }

  &__restart-game {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__game {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);

    &.bg-5 {
      transform: translate(-60%, -60%);

      @media (max-width: 1000px) {
        transform: translate(-50%, -60%);
      }
    }

    &.bg-8 {
      top: -50px;
      transform: translate(-50%, 0);

      @media (max-width: 1000px) {
        top: 0;
      }
      @media (max-width: 600px) {
        transform: translate(-50%, -10px);
      }
    }

    &.bg-10 {
      top: -50px;
      transform: translate(-45%, 0);

      @media (max-width: 1000px) {
        top: 0;
        transform: translate(-45%, 50px);
      }
      @media (max-width: 600px) {
        transform: translate(-50%, -10px);
      }
    }
  }

  &__background-mob {
    display: none;

    @media (max-width: 375px) {
      display: block;
      position: absolute;
      top: 290px;
      left: -50%;
      transform: translate(-50%, -50%);
      width: 347px;
      height: 426px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      box-shadow: 0 0 23px rgba(0, 0, 0, 0.25);
      border-radius: 46px;
      opacity: 0;
      transition: opacity 1s 1s;
      z-index: 0;
    }

    &.m-show {
      opacity: 1;
      left: 50%;
      animation-delay: 2s;
      animation-duration: 1s;
      animation-name: opacity;
    }
  }

  @keyframes opacity {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      left: -50%;
    }
  }

  &__buttons {
    margin-top: auto;
    display: flex;
    gap: 35px;
    justify-content: center;
    margin-bottom: 20px;
    opacity: 1;
    transition: opacity 0.5s;

    &.m-hide {
      opacity: 0;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
