export const winners = [
  {
    created_at: "2023-08-14",
    name: "Ольга",
    phone: "79*****3292",
    prize: "Набор Для Семьи",
  },
  {
    created_at: "2023-08-14",
    name: "Андрей",
    phone: "79*****8240",
    prize: "Набор Для Семьи",
  },
  {
    created_at: "2023-08-14",
    name: "Станислав",
    phone: "79*****9969",
    prize: "Набор Для Красоты",
  },
  {
    created_at: "2023-08-15",
    name: "Наталья",
    phone: "79*****2451",
    prize: "Набор Для Красоты",
  },
  {
    created_at: "2023-08-15",
    name: "Людмила",
    phone: "79*****0291",
    prize: "Набор Для Семьи",
  },
];
