<template>
  <div class="promo-faq" id="faq">
    <h2 class="title">
      Вопросы и ответы
    </h2>
    <Spoiler v-for="item, i in items" :key="i" :title="item.question" :id="'promo-faq-spoiler-' + i">
      <p v-html="item.answer">
      </p>
    </Spoiler>
  </div>
</template>

<script>
  import Spoiler from '@/components/Spoiler.vue';
  import { PROMO_FAQ_QUESTION } from '@/consts/promo.js';
  export default {
    name: 'PromoFaq',
    components: {
      Spoiler
    },
    data() {
      return {
        items: PROMO_FAQ_QUESTION
      };
    },
  };
</script>

<style scoped lang="scss">
  .promo-faq {
    margin-bottom: 78px;

    h2 {
      margin-bottom: 50px;
    }
  }
  @media(max-width: 1150px) {
    .promo-faq {
      margin-bottom: 67px;

      h2 {
        margin-bottom: 40px;
        padding-left: 12px;
      }
    }
  }
</style>
